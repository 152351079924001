// @ts-ignore
import { Form, Input, Row, Col, Alert } from 'antd'
// @ts-ignore
import { Button, Checkbox } from '@tdt-global/styleguide'
// @ts-ignore
import { utility } from '@tdt-global/utility'
import { useEffect, useRef, useState } from 'react'

function LoginForm() {
    const { auth$, login } = utility
    const [pending, setPending] = useState(false)
    const [error, setError] = useState<any>()
    const inputRef = useRef<any>(null)

    // Focus to username
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [inputRef])

    useEffect(() => {
        const sub = auth$.subscribe(({ pending, error }) => {
            // redirecting to /home when logged in will be done in the navbar. Cohesive code FTW!
            setPending(pending)
            setError(error)
        })
        return () => {
            sub.unsubscribe()
        }
    }, [])

    const onFinish = (values: any) => {
        login(values.username, values.password)
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo)
    }

    return (
        <>
            <div className='context'>
                <div className='title'>Inicia sesión</div>
                <div className='subtitle'>
                    Ingresa email y contraseña para acceder a tus productos.
                </div>

                {error && (
                    <Row className='alert'>
                        <Col span={24}>
                            <Alert
                                type='error'
                                message='Error de login'
                                showIcon
                                description={
                                    'Revisa los datos y vuelve a ingresarlos.'
                                }
                                closable
                                onClose={() =>
                                    setTimeout(() => setError(null), 5000)
                                }
                            />
                        </Col>
                    </Row>
                )}

                <Form
                    name='basic'
                    layout='vertical'
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete='off'>
                    <Form.Item
                        id='email-login'
                        label='Email'
                        name='username'
                        className='email-login'
                        rules={[
                            {
                                required: true,
                                message: 'Por favor ingresa tu email.',
                            },
                            {
                                type: 'email',
                                warningOnly: false,
                                message: 'Revisa la dirección de Email.',
                            },
                        ]}>
                        <Input
                            size='large'
                            placeholder='Ej. juanperez@correo.com'
                            ref={inputRef}
                        />
                    </Form.Item>

                    <Form.Item
                        id='password-login'
                        className='password-login'
                        label='Contraseña'
                        name='password'
                        ref={(input) => {
                            this.passwordInput = input
                        }}
                        rules={[
                            {
                                required: true,
                                message: 'Por favor ingresa tu contraseña.',
                            },
                        ]}>
                        <Input.Password
                            placeholder='Ingresa tu contraseña'
                            size='large'
                        />
                    </Form.Item>

                    <Form.Item
                        className='checkbox-login'
                        name='remember'
                        valuePropName='checked'>
                        <Checkbox>Recordarme</Checkbox>
                    </Form.Item>

                    <Form.Item
                        style={{ textAlign: 'right', marginBottom: '0' }}>
                        <Button
                            loading={pending}
                            disabled={false}
                            type='primary'
                            htmlType='submit'>
                            Iniciar sesión
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </>
    )
}

export default LoginForm
