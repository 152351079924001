// @ts-ignore
import { useState } from 'react'

import imagen from './Assets/Img/planning-Login.webp'
import favicon from './Assets/Img/TDT-Favicon.png'
import { Helmet } from 'react-helmet'
import LoginForm from './Components/LoginForm'

// const LoginForm = lazy(() => import('./Components/LoginForm'))

export default function Root(props) {
    const [imageLoaded, setImageLoaded] = useState(false)
    return (
        <div id='tdt-global-login'>
            <Helmet>
                <title>TDT Global</title>
                <link
                    rel='icon'
                    type='image/png'
                    href={favicon}
                    sizes='16x16'
                />
            </Helmet>

            <img
                src={imagen}
                alt='TDT global login'
                className={`smooth-image image-${
                    imageLoaded ? 'visible' : 'hidden'
                }`}
                onLoad={() => setImageLoaded(true)}
            />

            <div className='form-container'>
                <div className='box'>
                    <LoginForm />
                </div>

                <div className='box'>
                    <span className='box-down'>Un producto de </span>
                    <div className='logo'></div>
                </div>
            </div>
        </div>
    )
}
